<template>

<app-screen :title="title" background="white" :slim="true" toolbar="tasks" back="Categories">

	<com-summary :total="true" />

	<div class="tasks-list">

		<com-pending />
		<com-completed />

	</div>
	
</app-screen>

</template>

<script>

import comSummary from './tasks/Summary'
import comPending from './tasks/Pending'
import comCompleted from './tasks/Completed'

export default {

	components: {
		'com-summary': comSummary,
		'com-pending': comPending,
		'com-completed': comCompleted
	},

	computed: {

		title: function() {

			return this.$store.getters['category'].name

		}

	}

}

</script>

<style scoped>

.tasks-list {
	padding: 10px;
}

</style>
