<template>

<div class="photo">

	<div class="photo-capture" v-show="is.capturing">

		<div class="photo-back" v-on:click="onBackClick"><i class="fa fa-chevron-left"></i></div>

		<video id="video" class="video-js vjs-default-skin" playsinline></video>
		
		<app-button text="Start recording" class="photo-capture-button" v-on:click="onCaptureStart" v-if="!is.recording" />
		<app-button text="Stop recording" :loading="is.saving" class="photo-capture-button" v-on:click="onCaptureStop" v-if="is.recording || is.saving" />

	</div>

	<input ref="upload" type="file" accept=".mp4,.mov" v-on:change="onSelectedFile" class="photo-select-upload" />

	<div class="photo-preview" :class="{'is-previewing': video}">

		<video v-if="video && !is.capturing && !is.selecting" autoplay muted playsinline loop class="photo-preview-video">

			<source :src="video">

		</video>

		<i class="fa fa-video" v-if="!video"></i>

	</div>

	<div class="photo-options">

		<app-button text="Record video" v-if="!video && !window.is.mobile" :disabled="!device || saving" class="photo-options-item" v-on:click="onTakeClick" />
		<app-button text="Re-record video" v-if="video && !window.is.mobile" :disabled="!device || saving" class="photo-options-item" v-on:click="onTakeClick" />
		<app-button text="Record video" v-if="window.is.mobile" :loading="is.reading" :disabled="saving" class="photo-options-item" v-on:click="onSelectClick" />
		<app-button text="Select video..." v-if="!window.is.mobile" :loading="is.reading" :disabled="saving" class="photo-options-item" v-on:click="onSelectClick" />

	</div>

</div>

</template>

<script>

/* eslint-disable */
import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import videojs from 'video.js'

import 'webrtc-adapter'
import RecordRTC from 'recordrtc'

import Record from 'videojs-record/dist/videojs.record.js'

export default {

	props: ['saved', 'saving'],

	data: function() {

		return {
			device: false,
			video: false,
			is: {
				capturing: false,
				selecting: false,
				reading: false,
				saving: false,
				recording: false
			},
			player: '',
			options: {
                controls: false,
                autoplay: 'muted',
                fluid: true,
                loop: false,
                bigPlayButton: false,
				plugins: {
					record: {
						width: { min: 640, ideal: 640, max: 1280 },
               			height: { min: 480, ideal: 480, max: 920 },
                    	audio: true,
						video: true,
						maxLength: 30
                    }
				}
			}
		}

	},

	created: function() {

		this.device = true

		if (this.saved) {

			this.video = this.saved.asset 

		}

	},

	beforeDestroy: function() {

		if (this.player) this.player.record().destroy()

	},

	computed: {

		isWebm: function() {

			return this.video.indexOf('webm')

		},

		isMP4: function() {

			return this.video.indexOf('mp4')

		}

	},

	methods: {

		setupRecord: function() {

			/* eslint-disable no-console */
            this.player = videojs('#video', this.options)

			this.player.on('finishRecord', this.onCaptured.bind(this))

		},

		onBackClick: function() {

			this.is.capturing = false

		},

		onCaptureStart: function() {

			this.is.recording = true
			this.player.record().start()

		},

		onCaptureStop: function() {

			this.is.recording = false
			this.is.saving = true
			this.player.record().stop()

		},

		onSelectedFile: function(e) {

			this.is.reading = true

			var file = e.target.files[0]

			var reader = new FileReader()

			reader.onloadend = function() {

				this.video = reader.result 

				this.is.selecting = false
				this.is.reading = false

				this.$emit('selected', this.video)

			}.bind(this)

			reader.readAsDataURL(file)

		},

		onCaptured: function(data) {

			var reader = new FileReader()

			reader.onloadend = function() {

				this.video = reader.result.replace('data:video/x-matroska;codecs=avc1,opus;', 'data:video/webm;')

				this.is.capturing = false
				this.is.saving = false

				this.$emit('selected', this.video)

			}.bind(this)

			reader.readAsDataURL(this.player.recordedData)

		},

		onTakeClick: function() {

			navigator.mediaDevices.getUserMedia({
				audio: true, 
				video: true
			}).then(function(stream) {

				stream.stop()

				this.$nextTick(function() {
					
					this.setupRecord()

					this.player.record().getDevice()

					this.is.capturing = true
					this.is.selecting = false

				})
				
			}.bind(this), function() {

				alert('No webcam detected')

			})

		},

		onSelectClick: function() {

			this.is.capturing = false
			this.is.selecting = true

			this.$refs.upload.click()

		}

	}

}

</script>

<style scoped>

.photo-preview {
	background-color: #e4e4e4;
	width: 100%;
	height: 216px;
	border-radius: 9px;
	margin-bottom: 9px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	overflow: hidden;
}

.photo-preview.is-previewing {
	background-color: #000;
}

.photo-preview .fa {
	color: #428103;
	font-size: 96px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.photo-preview-video {
	width: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	height: auto;
	transform: translate(-50%, -50%);
}

.photo-options {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.photo-options-item {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 0;
	margin: 0px 2px;
}

.photo-capture {
	position: fixed;
	background-color: #000;
	z-index: 10;
	top: 0px;
	left: 0px;
	width: 100%;
	bottom: 0px;
	height: 100%;
	display: flex;
    align-items: center;
}

.photo-capture-button {
	left: 10px;
	right: 10px;
	bottom: 10px;
	width: calc(100% - 20px);
	position: absolute;
	z-index: 2;
}

.photo-select-upload {
	display: none;
}

.photo-back {
	width: 48px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 18px;
	color: #fff;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

</style>
