<template>

<div class="screen" v-on:click="onMenuClose" :class="{'is-centralised': centralised, 'is-text': textOnly, 'is-white': background === 'white', 'is-blue': blue, 'is-loading': loading, 'is-expanded': is.expanded}">

	<div class="screen-head" v-if="title || back">

		<div class="screen-head-back" v-on:click="onBackClick" v-if="back"><i class="fa fa-chevron-left"></i></div>

		<div class="screen-head-title">{{ title }}</div>

		<div class="screen-head-menu" v-on:click="onMenuClick($event)" v-if="menu">
			<i class="fa fa-remove" v-if="is.expanded"></i>
			<i class="fa fa-bars" v-if="!is.expanded"></i>
		</div>

	</div>

	<div class="screen-menu" v-on:click.stop="$emit('click')">

		<router-link :to="{name: 'Categories'}" class="screen-menu-item">Tips</router-link>
		<router-link :to="{name: 'Todo'}" class="screen-menu-item">To do</router-link>
		<router-link :to="{name: 'Rules'}" class="screen-menu-item">How it works</router-link>
		<router-link :to="{name: 'Message'}" class="screen-menu-item">Welcome video</router-link>
		<router-link :to="{name: 'Terms'}" class="screen-menu-item">Terms</router-link>
		<router-link :to="{name: 'Privacy'}" class="screen-menu-item">Privacy</router-link>
		<router-link :to="{name: 'Logout'}" class="screen-menu-item">Logout</router-link>

	</div>

	<div class="screen-tabs" v-if="tabs">

		<div class="screen-tabs-item" v-for="(tab, key) in tabs" :key="key" v-on:click="onTabClick(key)" :class="{'is-active': key === tabCurrent}">{{ tab }}</div>
	
	</div>

	<div class="screen-content" :class="{'has-background': background, 'is-slim': slim, 'is-blue': background === 'blue', 'is-green': background === 'green'}">
		
		<slot></slot>

	</div>

	<div class="screen-pips" v-if="pipTotal">

		<div class="screen-pips-item" v-for="pip in pipTotal" :key="pip" :class="{'is-active': pip === pipCurrent}"></div>

	</div>

	<div class="screen-buttons" v-if="hasButtons">

		<slot name="buttons"></slot>

	</div>

	<div class="screen-toolbar" v-if="toolbar">

		<div class="screen-toolbar-item" v-on:click="onToolbarClick('categories')" :class="{'is-active': toolbar === 'tasks'}"><i class="fa fa-star-o"></i> <span>Tips</span></div>
		<div class="screen-toolbar-item" v-on:click="onToolbarClick('todo')" :class="{'is-active': toolbar === 'todo'}"><i class="fa fa-star"></i> <span>To do</span><div class="counter" v-if="bookmarks">{{ bookmarks }}</div></div>
		<div class="screen-toolbar-item" v-on:click="onToolbarClick('leaderboard')" :class="{'is-active': toolbar === 'leaderboard'}"><i class="fa fa-poll"></i> <span>Leaderboard</span></div>
		<div class="screen-toolbar-item" v-on:click="onToolbarClick('feed')" :class="{'is-active': toolbar === 'feed'}"><i class="fa fa-image"></i> <span>Feed</span></div>

	</div>

</div>

</template>

<script>

export default {

	props: ['centralised', 'title', 'blue', 'background', 'back', 'menu', 'slim', 'toolbar', 'textOnly', 'loading', 'pipTotal', 'pipCurrent', 'tabs', 'tabCurrent'],

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		hasButtons: function() {

			if (this.$slots.buttons) {

				var exists = false

				this.$_.each(this.$slots.buttons, function(button) {

					if (button.tag !== undefined) exists = true

				})

				if (exists) return true

			}

			return false

		},

		bookmarks: function() {

			return this.$store.getters['session/bookmarked'].length

		}

	},

	methods: {

		onToolbarClick: function(value) {

			if (this.toolbar !== value) {

				this.$router.push({
					name: value.charAt(0).toUpperCase() + value.slice(1)
				})

			}

		},

		onTabClick: function(value) {

			this.$emit('tab', value)

		},

		onMenuClick: function(e) {

			e.stopPropagation()

			this.is.expanded = !this.is.expanded

		},

		onMenuClose: function() {

			this.is.expanded = false

		},

		onBackClick: function() {

			this.$router.push({
				name: this.back
			})

		}

	}

}

</script>

<style scoped>

.screen-menu {
	width: 160px;
	background-color: #2b5500;
	position: absolute;
	left: -160px;
	top: 0px;
	height: 100%;
	z-index: 100;
	display: flex;
	padding: 0px 10px;
	flex-direction: column;
}

.screen-menu-item {
	font-size: 14px;
	color: #fff;
	line-height: 48px;
}

.screen {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #e9f2e6;
	transition: margin-left 100ms linear;
}

.screen.is-white {
	background-color: #fff;
}

.screen.is-expanded {
	margin-left: 160px;
}

.screen.is-blue {
	background-color: #428103;
}

.screen.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yaZ+9/v/dbr/ez2/Sub+yaZ+02r+5TM/He//MTi/UKm+2S2+5vP/HvA/Mfk/Uao+yma+2i4++n0/d3u/fX6/VWv++Hw/fP5/cDh/a7Y/NHp/fD3/QAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.screen > * {
	transition: opacity 100ms linear;
}

.screen.is-loading > * {
	opacity: 0;
	pointer-events: none;
}

.screen-head {
	height: 48px;
	flex-shrink: 0;
}

.screen-head-back {
	width: 48px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 18px;
	color: #428103;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

.screen-head-menu {
	width: 48px;
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 18px;
	color: #428103;
	text-align: center;
	line-height: 48px;
	cursor: pointer;
	z-index: 2;
}

.screen.is-expanded .screen-head-menu {
	color: #fff;
	background-color: #2b5500;
}

.screen-head-title {
	width: 100%;
	text-align: center;
	line-height: 48px;
	color: #428103;
	font-weight: bold;
	font-size: 14px;
	text-transform: uppercase;
}

.screen-content {
	padding: 10px;
	flex-grow: 1;
	width: 100%;
	overflow-y: auto;
}

.screen-content.is-slim {
	padding: 10px 0px;
}

.screen-content.has-background {
	background-size: cover;
}

.screen-content.has-background.is-blue {
	background-image: url(~@/assets/background-blue.png);
	background-position: 100% 100%;
}

.screen-content.has-background.is-green {
	background-position: 100% 0%;
	background-image: url(~@/assets/background-green.jpg);
}

.screen.is-centralised .screen-content {
	justify-content: center;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.screen-pips {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	justify-content: center;
	padding: 10px 0px;
}

.screen-pips-item {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin: 0px 5px;
	background-color: #BCE0FD;
}

.screen-pips-item.is-active {
	background-color: #428103;
}

.screen-buttons {
	padding: 10px;
	flex-shrink: 0;
	display: flex;
	width: 100%;
	flex-direction: column;
}

.screen-buttons >>> .button {
	margin-bottom: 10px;
}

.screen-buttons >>> .button:last-child {
	margin-bottom: 0px;
}

.screen-toolbar {
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	height: 64px;
	background-color: #428103;
}

.screen-toolbar-item {
	width: 33.3%;
	display: flex;
	color: rgba(255, 255, 255, 0.75);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.screen-toolbar-item .counter {
	padding: 2px 4px;
    position: absolute;
    background-color: #de2828;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    border-radius: 8px;
}

.screen-toolbar-item.is-active {
	color: #fff;
}

.screen-toolbar-item .fa {
	height: 32px;
	font-size: 24px;
}

.screen-toolbar-item span {
	display: block;
	text-transform: uppercase;
	font-size: 10px;
}

.screen-tabs {
	height: 40px;
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
}

.screen-tabs-item {
	flex-grow: 1;
	flex-basis: 0;
	flex-shrink: 0;
	line-height: 40px;
	color: #428103;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	text-align: center;
}

.screen-tabs-item.is-active {
	border-bottom: 3px solid #428103;
}

.screen.is-text .screen-content >>> h2 {
	color: #428103;
	width: 100%;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
}

.screen.is-text .screen-content >>> p {
	color: #428103;
	width: 100%;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 32px;
}

.screen.is-text .screen-content >>> ul {
	list-style: disc;
	margin-bottom: 32px;
	list-style-position: outside;
}

.screen.is-text .screen-content >>> ol {
	list-style: decimal;
	margin-bottom: 32px;
	list-style-position: outside;
}

.screen.is-text .screen-content >>> li {
	color: #428103;
	width: 100%;
	font-size: 14px;
	line-height: 24px;
}

</style>