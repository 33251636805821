import '@/assets/reset.css'

import 'share-buttons'
import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";

import _ from 'underscore'
import moment from 'moment'
import constants from './constants'
import router from './router'

import store from './service/store'
import pubsub from './service/pubsub'
import api from './service/api'

import mixInjection from './mixin/injection'
import mixShortcuts from './mixin/shortcuts'
import mixTime from './mixin/time'
import mixWindow from './mixin/window'

Vue.use(VueGtag, {
	config: { id: "UA-194670768-1" }
}, router);

Vue.config.productionTip = false

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

function updateTime() {

	store.commit('time/current', moment().utc().unix())
	setTimeout(updateTime, 250)

}

setTimeout(updateTime, 500)

Vue.filter('round', function (value, decimals, force) {

	decimals = decimals || 0
	force = force || false

	return ((value % 1 !== 0 || force) && value !== undefined) ? value.toFixed(decimals) : value

})

Vue.filter('nth', function (value) {

	var remainder = value % 10

	if (remainder === 1 && value !== 11) return value.toString() + 'st'
	if (remainder === 2 && value !== 12) return value.toString() + 'nd'
	if (remainder === 3 && value !== 13) return value.toString() + 'rd'

	return value.toString() + 'th'

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().format(format) : ''

})

Vue.mixin(mixWindow)
Vue.mixin(mixTime)
Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

router.beforeEach(function(to, from, next) {

	if (!store.getters['session'] && !_.contains(['Login', 'Verify', 'Splash', 'Terms', 'Privacy'], to.name)) {

		next({
			name: 'Splash'
		})

	} else {

		if (!_.contains(['Login', 'Verify', 'Splash', 'Countdown', 'Locked', 'Terms', 'Privacy'], to.name)) {

			if(store.getters['settings'].date.start > 0 && store.getters['settings'].date.start > moment().utc().unix()) {
	
				next({
					name: 'Countdown'
				})

			} else if(
				(
					store.getters['settings'].date.end > 0 && store.getters['settings'].date.end < moment().utc().unix()
				) 
				||
				(
					store.getters['session'].date.deadline > 0 && store.getters['session'].date.deadline < moment().utc().unix()
				)
			) {
	
				next({
					name: 'Locked'
				})

			} else if ((!store.getters['tasks'].length || !store.getters['settings']) && !_.contains(['Splash'], to.name)) {
	
				next({
					name: 'Splash'
				})
		
			} else {
	
				next()
	
			}

		} else {

			if ((!store.getters['tasks'].length || !store.getters['settings']) && !_.contains(['Splash'], to.name)) {
	
				next({
					name: 'Splash'
				})

			} else {

				next()

			}

		}

	}

})

new Vue({
	_,
	moment,
	router,
	constants,
	store,
	api,
	pubsub,
	render: h => h(App),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
