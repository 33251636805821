<template>

<app-screen title="Tasks" toolbar="tasks" :menu="true">

	<div class="categories-text" v-html="texts.ca.text"></div>

	<com-category v-for="category in categories" :item="category" :key="category.id" />
	
</app-screen>

</template>

<script>

import comCategory from './categories/Item'

export default {

	components: {
		'com-category': comCategory
	},

	computed: {

		categories: function() {

			return this.$store.getters['categories']

		}
			
	}

}

</script>

<style scoped>

.categories-text {
	margin-bottom: 32px;
}

.categories-text >>> p {
	color: #428103;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	letter-spacing: 1;
	margin-bottom: 16px;
}

.categories-text >>> p:last-child {
	margin-bottom: 0px;
}


</style>
