<template>

<div class="button" v-on:click="onClick" :class="{'is-errored': errored, 'is-natural': natural, 'is-loading': loading && !progress, 'is-disabled': disabled, 'is-inverted': invert}">

	<div class="button-text" v-if="text && !loading" v-html="text"></div>

	<div class="button-text" v-if="progress === 100 && loading">Saving...</div>

	<div class="button-progress" v-if="progress && progress < 100">
		<div class="button-progress-bar" :style="{width: progress + '%'}"></div>
		<div class="button-progress-value">{{ progress }}%</div>
	</div>

</div>

</template>

<script>

export default {

	props: ['text', 'loading', 'errored', 'progress', 'natural', 'disabled', 'invert'],

	methods: {

		onClick: function(e) {

			if (!this.disabled) this.$emit('click', e)

		}

	}

}

</script>

<style scoped>

.button {
	background-color: #428103;
	color: #fff;
	border-radius: 9px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	overflow: hidden;
	cursor: pointer;
}

.button.is-errored {
	background-color: #fb2626;
}

.button.is-inverted {
	background-color: #fff;
	border: 2px solid #428103;
	color: #428103;
}

.button.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.button.is-loading:not(.is-disabled) {
	pointer-events: none;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAACaZ+////0Sn+02r+zeg+/j7/f///9br/Y/K/KzX/F+0++Hw/b/g/YjH/KjW/Fyy+93u/fr8/bve/Tqi+0ao+y6c+87n/UKm+zCd+2O1+3W+/FKt+zOf+wAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.button-text {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

.button.is-natural .button-text {
	text-transform: initial;
	padding: 0px 10px;
}

.button-progress {
	height: 10px;
	position: absolute;
	width: 100%;
	left: 0px;
	bottom: 0px;
}

.button-progress-bar {
	transition: width 100ms linear;
	background-image: linear-gradient(45deg, #8abccc 25%, #428103 25%, #428103 50%, #8abccc 50%, #8abccc 75%, #428103 75%, #428103 100%);
	background-size: 16.00px 16.00px;
	height: 10px;
}

.button-progress-value {
	position: absolute;
	left: 0%;
	text-align: center;
	width: 100%;
	bottom: 22px;
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	height: 10px;
}

</style>