<template>

<app-screen title="To do" background="white" :slim="true" toolbar="todo" :menu="true">

	<com-summary :total="true" />

	<div class="todo-list">

		<com-task :task="task" v-for="task in tasks" :key="task.id" source="Todo" />

		<div class="todo-empty" v-if="!tasks.length">You have not yet saved any tips to your to do list.</div>

	</div>
		
</app-screen>

</template>

<script>

import comSummary from './tasks/Summary'
import comTask from './tasks/Task'

export default {

	components: {
		'com-summary': comSummary,
		'com-task': comTask
	},

	computed: {

		tasks: function() {

			return this.$_.filter(this.$store.getters['tasks'], function(task) {

				return this.$_.contains(this.$store.getters['session/bookmarked'], task.id)

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.todo-empty {
	font-size: 24px;
	text-align: center;
	padding: 20px;
	color: #428103;
	line-height: 32px;
	opacity: 0.75;
	font-weight: 400;
	padding: 20px;
}

.todo-list {
	padding: 10px;
}

</style>
