<template>

<app-screen :title="task.name" :back="back" background="white">

	<template v-if="!is.completed && !is.permit">

		<div class="task-summary">

			<div class="task-summary-icon">

				<i class="fa fa-camera" v-if="task.type === $constants.task.photo"></i>
				<i class="fa fa-video" v-if="task.type === $constants.task.video"></i>
				<i class="fa fa-list-ol" v-if="task.type === $constants.task.multi"></i>
				<i class="fa fa-microphone" v-if="task.type === $constants.task.audio"></i>

			</div>
			
			<div class="task-summary-text">
				Task
			</div>

			<div class="task-summary-points">
				+{{ points }}
			</div>

		</div>

		<div class="task-text" v-html="task.text"></div>

		<div class="task-bookmark" v-on:click="onBookmarkClick" v-if="!bookmarked && !completed">Save as To Do <i class="fa fa-star-o"></i></div>
		<div class="task-bookmark" v-on:click="onBookmarkClick" v-if="bookmarked && !completed">Save as To Do <i class="fa fa-star"></i></div>

		<com-options v-if="task.type === $constants.task.multi" :saving="is.saving" :saved="saved" :options="task.options" :selected="multi.current" :disabled="multi.selected" v-on:selected="onOptionSelected" />
		<com-video v-if="task.type === $constants.task.video" :saving="is.saving" :saved="saved" v-on:selected="onVideoSelected" />
		<com-photo v-if="task.type === $constants.task.photo" :saving="is.saving" :saved="saved" v-on:selected="onPhotoSelected" />
		<com-audio v-if="task.type === $constants.task.audio" :saving="is.saving" :saved="saved" v-on:selected="onAudioSelected" />

	</template>

	<div class="task-completed" v-if="is.completed">

		<div class="task-completed-points">+{{ points }}</div>

		<div class="task-completed-text">Nice!</div>

	</div>

	<div class="task-permit" v-if="is.permit">

		<div class="task-text">

			<p>{{ texts.ta.permit }}</p>

		</div>

	</div>

	<template v-slot:buttons>

		<div class="task-warning" v-if="is.warning && !is.completed">{{ texts.ta.warn }}</div>

		<app-button :text="buttonText" :progress="progress" v-on:click="onConfirmClick" :disabled="!valid || is.disabled" :loading="is.saving" :errored="multi.errored" v-if="!is.completed && !is.locked && !is.permit" />
		<app-button text="Continue" v-on:click="onContinueClick" v-if="is.completed && !is.permit" />
		<app-button text="Include" v-on:click="onPermitYesClick" v-if="is.permit" />
		<app-button text="Do not include" :invert="true" v-on:click="onPermitNoClick" v-if="is.permit" />

	</template>

</app-screen>

</template>

<script>

import comOptions from './task/Options'
import comVideo from './task/Video'
import comPhoto from './task/Photo'
import comAudio from './task/Audio'

export default {

	components: {
		'com-options': comOptions,
		'com-video': comVideo,
		'com-photo': comPhoto,
		'com-audio': comAudio
	},

	data: function() {

		return {
			progress: 0,
			points: 0,
			multi: {
				selected: [],
				current: 0,
				errored: false
			},
			photo: false,
			video: false,
			audio: false,
			is: {
				warning: false,
				permit: false,
				saving: false,
				locked: false,
				disabled: false,
				completed: false
			}
		}

	},

	created: function() {

		if (this.saved) {

			this.points = this.saved.points

			if (this.task.type === this.$constants.task.photo) {

				this.photo = this.saved.asset
				this.is.disabled = true

			} else if (this.task.type === this.$constants.task.video) {

				this.video = this.saved.asset
				this.is.disabled = true

			} else if (this.task.type === this.$constants.task.audio) {

				this.audio = this.saved.asset
				this.is.disabled = true

			} else if (this.task.type === this.$constants.task.multi) {

				this.multi.selected = this.saved.options

				if (this.saved.status === this.$constants.task.complete) {
					
					this.multi.current = this.multi.selected[this.multi.selected.length - 1]
					this.is.locked = true

				} else {

					this.points = Math.floor(this.saved.points / 2)

				}

			}

		} else {

			this.points = this.task.points

		}

		this.$pubsub.$on('api.progress', this.onUploadProgress.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('api.progress', this.onUploadProgress.bind(this))

	},

	computed: {

		back: function() {

			return this.$route.query.from || 'Tasks'

		},

		saved: function() {

			return this.$store.getters['session/tasks'][this.task.id]

		},

		valid: function() {

			if (this.task.type === this.$constants.task.multi && this.multi.current) {

				return true

			} else if (this.task.type === this.$constants.task.photo && this.photo) {

				return true

			} else if (this.task.type === this.$constants.task.video && this.video) {

				return true

			} else if (this.task.type === this.$constants.task.audio && this.audio) {

				return true

			} 

			return false

		},

		buttonText: function() {

			return (this.multi.errored) ? 'Nope, try again!' : 'Confirm'

		},

		task: function() {

			return this.$_.findWhere(this.$store.getters['tasks'], {
				id: this.$route.query.task
			})

		},

		completed: function() {

			return this.$_.contains(this.$store.getters['session/tasks/completed'], this.task.id)

		},

		bookmarked: function() {

			return this.$_.contains(this.$store.getters['session/bookmarked'], this.task.id)

		}

	},

	methods: {

		onBookmarkClick: function() {

			this.$api.request('bookmark', {
				task: this.task.id
			}).then(function(response) {

				this.$store.commit('session', response.session)

			}.bind(this))

		},

		sizeCheck: function(src) {

			src = src.split('base64,')

			this.is.warning = atob(src[1]).length > this.$constants.upload.warning

		},

		onUploadProgress: function(percent) {

			if (!this.task) return false

			if (this.task.type !== this.$constants.task.multi) {

				this.progress = percent 

			} else {

				this.progress = false

			}

		},

		onOptionSelected: function(id) {

			this.multi.current = id
			this.multi.errored = false

		},

		onPhotoSelected: function(photo) {

			this.photo = photo
			this.is.disabled = false

		},

		onVideoSelected: function(video) {

			this.video = video
			this.is.disabled = false

		},

		onAudioSelected: function(audio) {

			this.audio = audio
			this.is.disabled = false

		},

		onContinueClick: function() {

			if (this.$store.getters['session/tasks/completed'].length === this.$store.getters['tasks'].length) {

				this.$router.push({
					name: 'Completed'
				})

			} else {

				this.$router.push({
					name: 'Tasks'
				})
				
			}

		},

		onConfirmed: function(response) {

			var saved = this.saved

			this.$store.commit('session', response.session)

			this.is.saving = false
			
			if (!saved) {

				this.is.completed = true

			} else {

				if (saved.status === this.$constants.task.incomplete) {

					this.is.completed = true

				} else {

					this.$router.push({
						name: 'Tasks'
					})

				}

			}

		},

		onPermitYesClick: function() {

			this.$store.commit('session/permit/feed', 2)

			this.onConfirmClick()

		},

		onPermitNoClick: function() {

			this.$store.commit('session/permit/feed', 1)

			this.onConfirmClick()

		},

		onConfirmClick: function() {

			if (!this.$store.getters['session'].permit.feed && this.task.type !== this.$constants.task.multi) {

				this.is.permit = true

			} else {

				this.is.permit = false

				if (this.task.type === this.$constants.task.multi) {

					this.is.saving = true

					this.$api.request(this.$constants.api.tasks.save, {
						option: this.multi.current,
						task: this.task.id,
						points: this.points,
						public: this.$store.getters['session'].permit.feed
					}).then(function(response) {

						if (response.correct) {

							this.onConfirmed(response)

						} else {

							this.$store.commit('session', response.session)

							this.multi.selected.push(this.multi.current)
							this.multi.current = 0
							this.multi.errored = true

							this.points = Math.floor(this.points / 2)

							this.is.saving = false

						}

					}.bind(this), function() {

						this.is.saving = false

					}.bind(this))

				} else if (this.task.type === this.$constants.task.photo) {

					this.is.saving = true
					this.sizeCheck(this.photo)
					
					var img = document.createElement('img')

					img.onload = function() {

						var scale = 1

						if (img.width > 1920) scale = 1920 / img.width 
						if (img.height > 1920 && img.width < 1920) scale = 1920 / img.height 

						var canvas = document.createElement('canvas')
						
						canvas.width = img.width * scale
						canvas.height = img.height * scale

						var ctx = canvas.getContext('2d')

						ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

						var data = canvas.toDataURL('image/jpeg', 0.75)

						this.$api.request(this.$constants.api.tasks.save, {
							photo: data,
							task: this.task.id,
							points: this.points,
							public: this.$store.getters['session'].permit.feed
						}).then(this.onConfirmed.bind(this), function() {

							this.is.saving = false

						}.bind(this))

					}.bind(this)

					img.setAttribute('src', this.photo)

				} else if (this.task.type === this.$constants.task.video) {

					this.is.saving = true
					this.sizeCheck(this.video)

					this.$api.request(this.$constants.api.tasks.save, {
						video: this.video,
						task: this.task.id,
						points: this.points,
						public: this.$store.getters['session'].permit.feed
					}).then(this.onConfirmed.bind(this), function() {

						this.is.saving = false

					}.bind(this))

				} else if (this.task.type === this.$constants.task.audio) {

					this.is.saving = true

					this.$api.request(this.$constants.api.tasks.save, {
						audio: this.audio,
						task: this.task.id,
						points: this.points,
						public: this.$store.getters['session'].permit.feed
					}).then(this.onConfirmed.bind(this), function() {

						this.is.saving = false

					}.bind(this))

				}

			}

		}

	}

}

</script>

<style scoped>

.task-name {
	font-size: 20px;
	font-weight: bold;
	color: #428103;
	margin-bottom: 48px;
}

.task-summary {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	margin-bottom: 16px;
	width: 100%;
}

.task-summary-icon {
	font-size: 24px;
	line-height: 36px;
	color: #428103;
}

.task-summary-text {
	font-size: 14px;
	line-height: 36px;
	flex-grow: 1;
	text-transform: uppercase;
	color: #428103;
	padding: 0px 20px 0px 10px;
	font-weight: bold;
}

.task-summary-points {
	font-size: 16px;
	line-height: 34px;
	border: 1px solid #428103;
	border-radius: 8px;
	width: 48px;
	text-align: center;
	color: #428103;
	font-weight: bold;
}

.task-text {
	margin-bottom: 32px;
}

.task-text >>> p {
	color: #428103;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 16px;
}

.task-text >>> p:last-child {
	margin-bottom: 0px;
}

.task-text >>> a {
	border-bottom: 1px dotted #428103;
	font-weight: 400;
}

.task-completed {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.task-completed-points {
	width: 176px;
	height: 176px;
	border: 1px solid #428103;
	border-radius: 50%;
	line-height: 176px;
	text-align: center;
	font-weight: bold;
	font-size: 64px;
	color: #428103;
}

.task-completed-text {
	text-align: center;
	color: #428103;
	font-size: 14px;
	margin-top: 16px;
	text-transform: uppercase;
}

.task-warning {
	position: absolute;
	left: 10px;
	width: calc(100% - 20px);
	bottom: 60px;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	font-weight: 300;
	padding: 8px 10px;
	border-radius: 9px;
	background-color: #fae38e;
	z-index: 2;
}

.task-warning:after {
	content: ' ';
	position: absolute;
	left: 50%;
	margin-left: -10px;
	bottom: -10px;
	width: 0; 
	height: 0; 
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #fae38e;
}

.task-bookmark {
	line-height: 36px;
	text-align: right;
	color: #428103;
	font-size: 16px;
	cursor: pointer;
}

.task-bookmark .fa {
	font-size: 24px;
	cursor: pointer;
	margin-left: 10px;
	margin-bottom: 32px;
}

</style>
