<template>

<div class="options" :class="{'is-locked': locked}">

	<app-button v-for="option in options" :invert="selected !== option.id" :disabled="$_.contains(disabled, option.id) && !locked" :natural="true" :key="option.id" :text="option.text" class="options-item" v-on:click="onOptionClick(option.id)" />

</div>

</template>

<script>

export default {

	props: ['options', 'disabled', 'selected', 'errored', 'saved'],

	computed: {

		locked: function() {

			return (this.saved) ? this.saved.status : false

		}

	},

	methods: {

		onOptionClick: function(id) {

			if (!this.locked) this.$emit('selected', id)

		}

	}

}

</script>

<style scoped>

.options.is-locked {
	pointer-events: none;
}

.options-item {
	margin-bottom: 16px;
}

.options-item:last-child {
	margin-bottom: 0px;
}

</style>
