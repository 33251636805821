<template>

<div class="item" :style="{backgroundImage: previewImage}" v-on:click="onExpandClick">

	<i class="fa fa-play" v-if="item.type === $constants.task.video"></i>
	<i class="fa fa-play" v-if="item.type === $constants.task.audio"></i>

	<div class="item-expand" v-if="is.expanded" :style="{backgroundImage: backgroundImage}">

		<i class="fa fa-remove item-expand-close" v-on:click="onCloseClick"></i>

		<video playsinline autoplay controls loop v-if="item.type === $constants.task.video">
			<source :src="item.asset" />
		</video>

		<audio controls v-if="item.type === $constants.task.audio">
			<source :src="item.asset" />
		</audio>

		<div class="item-expand-name">{{ item.user }}</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		backgroundImage: function() {

			return (this.item.type === this.$constants.task.photo) ? 'url(' + this.item.asset + ')' : false

		},

		previewImage: function() {

			return 'url(' + this.item.preview + ')'

		}

	},

	methods: {

		onExpandClick: function() {

			this.is.expanded = true

		},

		onCloseClick: function(e) {

			e.stopPropagation()

			this.is.expanded = false

		}

	}

}

</script>

<style scoped>

.item {
	width: calc(33.3% - 7px);
	margin: 0px 10px 10px 0px;
	background-color: #000;
	background-position: 50% 50%;
	background-size: cover;
	overflow: hidden;
	cursor: pointer;
}

.item:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.item:nth-child(3n) {
	margin-right: 0px;
}

.item > .fa {
	font-size: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 2;
	transform: translate(-50%, -50%);
	border: 2px solid #fff;
	width: 70px;
	color: #fff;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 50%;
}

.item video {
	width: 200px;
	height: auto;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	z-index: 1;
	opacity: 0.5;
}

.item audio {
	width: calc(100% - 20px);
	height: 64px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 50%;
	z-index: 1;
}

.item-expand-name {
	position: fixed;
	bottom: 0px;
	padding: 10px;
	color: #fff;
	text-align: center;
	font-size: 24px;
	background-color: rgba(0, 0, 0, 0.8);
	font-weight: bold;
	width: 100%;
	left: 0px;
	z-index: 2;
}

.item-expand {
	position: fixed;
	left: 0px;
	top: 0px;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 10;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.item-expand-close {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 24px;
	z-index: 2;
	width: 48px;
	height: 48px;
	border-radius: 24px;
	text-align: center;
	line-height: 48px;
	border: 0px;
	background-color: #428103;
	color: #fff;
}

.item-expand video {
	width: 100%;
	height: auto;
	z-index: 1;
	opacity: 1;
	max-height: calc(100% - 68px);
}

</style>
